/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-13 13:08:39
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-22 17:27:35
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getInboundList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/package-list",
      params
    );
  },
  getInboundItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/get-package-products",
      params
    );
  },
  getInboundInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/get-package-item",
      params
    );
  },
  updateInboundInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/update-package-item",
      params
    );
  },
  updateInboundWarehouseStockInBatchStatus(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/wms/update-warehouse-stock-in-batch-status",
      params
    );
  },
  inboundMatched(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/matched",
      params
    );
  },
  uploadInboundItemImages() {
    return Config.fulfillmentApiUrl + "/api/wms/upload-images";
  },
  uploadInboundItemImagesRequest(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/upload-images",
      params
    );
  },
  deleteInboundItemImage(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/delete-upload-image",
      params
    );
  },
  stockBatchScanCode(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-batch/scan-code",
      params
    );
  },
  importPackageReportsStockBatch() {
    return (
      Config.fulfillmentApiUrl + "/api/tms/stock-batch/import-package-reports"
    );
  },
  exportPackageReportsStockBatch(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-batch/export-package-reports",
      params,
      { responseType: "blob" }
    );
  },
  exportPackageMismatchedReportsStockBatch(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/tms/stock-batch/export-package-mismatched-reports",
      params,
      { responseType: "blob" }
    );
  },
};
