/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-06-01 16:47:30
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-17 17:56:46
 */
import { CommonStatus, TaggingItem } from "../interface/common";
import {
  BadgeStyle,
  CommonLanguageType,
  CommonMapType,
  NumberOrString,
  SvgStyle,
  TextStyle,
} from "../type/common";
import {
  CustomerClearanceMode,
  MbAnnualLimitVerification,
  PartialShipment,
  PartialWholeShipment,
  PriorityHandling,
  QaReport,
  SalesOrderDepositPaymentStatus,
  SalesOrderDistributionRFM,
  SalesOrderFulfillmentStatus,
  SalesOrderNewStatus,
  SalesOrderPaymentStatus,
  SalesOrderPriorityLevel,
  SalesOrderSettlementStatus,
  SalesOrderState,
  SalesOrderStatus,
  SalesOrderType,
  SettlementType,
} from "../type/salesOrder";
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getSalesOrderModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setSalesOrderName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setSalesOrderName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/sales-order/";
  result.parentName = language("common.salesOrder");
  if (route.path.match("order-overview")) {
    strName = language("common.orderOverview");
    listPath += "order-overview";
  } else if (route.path.match("order-verification")) {
    strName = language("common.orderVerification");
    listPath += "order-verification";
  } else if (route.path.match("draft-orders")) {
    strName = language("common.draftOrders");
    listPath += "draft-orders";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getSalesOrderDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "sales-order-order-overview-add":
      result.currentName = language("common.add");
      setSalesOrderName(language, route, result, router);
      break;
    case "sales-order-order-overview-overview":
    case "sales-order-order-verification-overview":
    case "sales-order-draft-order-overview":
      result.currentName = language("salesOrder.overview");
      setSalesOrderName(language, route, result, router);
      break;
    case "sales-order-order-overview-sales-profilling":
    case "sales-order-order-verification-sales-profilling":
    case "sales-order-draft-order-sales-profilling":
      result.currentName = language("salesOrder.salesProfilling");
      setSalesOrderName(language, route, result, router);
      break;
    case "sales-order-order-overview-fulfillment-overview":
    case "sales-order-order-verification-fulfillment-overview":
      result.currentName = language("fulfillmentOrder.fulfillmentOverview");
      setSalesOrderName(language, route, result, router);
      break;
    case "sales-order-order-overview-order-tracking":
    case "sales-order-order-verification-order-tracking":
      result.currentName = language("salesOrder.orderTracking");
      setSalesOrderName(language, route, result, router);
      break;
    case "sales-order-order-overview-exception-handlings":
    case "sales-order-order-verification-exception-handlings":
      result.currentName = language("common.exceptionHandling");
      setSalesOrderName(language, route, result, router);
      break;
    case "sales-order-order-overview-import":
      result.currentName = language("salesOrder.importOrders");
      setSalesOrderName(language, route, result, router);
      break;
    case "sales-order-order-overview-support-tickets":
    case "sales-order-order-verification-support-tickets":
      result.currentName = language("salesOrder.supportTickets");
      setSalesOrderName(language, route, result, router);
      break;
  }
  return result;
};

/**
 * @description: 获取销售订单状态（文本和样式）
 * @return CommonStatus
 */
export const getSalesOrderStatus = (
  status: string,
  language: CommonLanguageType
): CommonStatus => {
  let str = "",
    statusClass = "";
  switch (status) {
    case SalesOrderStatus.Pending:
    case SalesOrderState.Pending:
      // case SalesOrderState.New:
      str = language("salesOrder.orderStatusPending");
      statusClass = BadgeStyle.LightWarning; //黄
      break;
    case SalesOrderStatus.PendingPayment:
    case SalesOrderState.PendingPayment:
      str = language("salesOrder.orderStatusPendingPayment");
      statusClass = BadgeStyle.LightWarning; //黄
      break;
    case SalesOrderStatus.SellOnCredit:
    case SalesOrderState.SellOnCredit:
      str = language("salesOrder.orderStatusSellOnCredit");
      statusClass = BadgeStyle.LightWarning; //黄
      break;
    case SalesOrderState.New:
    case SalesOrderStatus.Processing:
    case SalesOrderState.Processing:
      str = language("salesOrder.orderStatusProcessing");
      statusClass = BadgeStyle.LightPrimary; // 蓝
      break;
    case SalesOrderStatus.PartialShipped:
    case SalesOrderState.PartialShipped:
      str = language("salesOrder.orderStatusPartialShipped");
      statusClass = BadgeStyle.LightPrimary; // 蓝
      break;
    case SalesOrderStatus.Shipped:
    case SalesOrderState.Shipped:
      str = language("salesOrder.orderStatusShipped");
      statusClass = BadgeStyle.LightPrimary; // 蓝
      break;
    case SalesOrderStatus.Complete:
    case SalesOrderState.Complete:
      str = language("salesOrder.orderStatusComplete");
      statusClass = BadgeStyle.LightSuccess; // 绿
      break;
    case SalesOrderStatus.Refunded:
    case SalesOrderState.Refunded:
    case SalesOrderState.Closed:
      str = language("salesOrder.orderStatusRefunded");
      statusClass = BadgeStyle.LightInfo; // 紫
      break;
    case SalesOrderStatus.Canceled:
    case SalesOrderState.Canceled:
    case SalesOrderState.PaymentReview:
      str = language("salesOrder.orderStatusCanceled");
      statusClass = BadgeStyle.LightDark; // 黑
      break;
    case SalesOrderStatus.OnHold:
    case SalesOrderState.OnHold:
      str = language("salesOrder.orderStatusHoldOn");
      statusClass = BadgeStyle.LightDanger; // 红
      break;
    default:
      statusClass = BadgeStyle.LightPrimary; //蓝
  }
  return { str, statusClass };
};

/**
 * @description: 获取销售订单状态（旧）
 * @return TaggingItem[]
 */
export const getSalesOrderStateOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("salesOrder.orderStatusPending"),
      value: SalesOrderState.Pending,
    },
    {
      label: language("salesOrder.orderStatusPendingPayment"),
      value: SalesOrderState.PendingPayment,
    },
    {
      label: language("salesOrder.orderStatusSellOnCredit"),
      value: SalesOrderState.SellOnCredit,
    },
    {
      label: language("salesOrder.orderStatusProcessing"),
      value: SalesOrderState.Processing,
    },
    {
      label: language("salesOrder.orderStatusPartialShipped"),
      value: SalesOrderState.PartialShipped,
    },
    {
      label: language("salesOrder.orderStatusShipped"),
      value: SalesOrderState.Shipped,
    },
    {
      label: language("salesOrder.orderStatusComplete"),
      value: SalesOrderState.Complete,
    },
    {
      label: language("salesOrder.orderStatusRefunded"),
      value: SalesOrderState.Refunded,
    },
    {
      label: language("salesOrder.orderStatusCanceled"),
      value: SalesOrderState.Canceled,
    },
    {
      label: language("salesOrder.orderStatusHoldOn"),
      value: SalesOrderState.OnHold,
    },
  ];
};

/**
 * @description: 获取订单状态（新）
 * @return {*}
 */
export const getSalesOrderStatusMap = (
  status: number,
  language: CommonLanguageType
): CommonMapType => {
  const result = new Map([
    [
      SalesOrderNewStatus.New,
      {
        label: language("salesOrder.statusNew"),
        color: BadgeStyle.LightWarning,
      },
    ],
    [
      SalesOrderNewStatus.Submit,
      {
        label: language("salesOrder.statusSubmit"),
        color: BadgeStyle.LightWarning,
      },
    ],
    [
      SalesOrderNewStatus.Verified,
      {
        label: language("salesOrder.statusVerified"),
        color: BadgeStyle.LightPrimary,
      },
    ],
    [
      SalesOrderNewStatus.Processing,
      {
        label: language("salesOrder.statusProcessing"),
        color: BadgeStyle.LightPrimary,
      },
    ],
    [
      SalesOrderNewStatus.FulfillmentCompleted,
      {
        label: language("salesOrder.statusFulfillmentComplete"),
        color: BadgeStyle.LightSuccess,
      },
    ],
    [
      SalesOrderNewStatus.OrderCompleted,
      {
        label: language("salesOrder.statusSalesOrderComplete"),
        color: BadgeStyle.LightSuccess,
      },
    ],
    // 特殊状态
    [
      SalesOrderNewStatus.Canceled,
      {
        label: language("salesOrder.statusCanceled"),
        color: BadgeStyle.Secondary,
      },
    ],
    [
      SalesOrderNewStatus.Refunded,
      {
        label: language("salesOrder.statusRefunded"),
        color: BadgeStyle.Secondary,
      },
    ],
    [
      SalesOrderNewStatus.Closed,
      {
        label: language("salesOrder.statusClosed"),
        color: BadgeStyle.Light,
      },
    ],
  ]);
  return result.get(status);
};

/**
 * @description: 获取结算状态
 * @return {*}
 */
export const getSettlementStatusMap = (
  status: number,
  language: CommonLanguageType
): CommonMapType => {
  const result = new Map([
    [
      SalesOrderSettlementStatus.SettlementConfirmation,
      {
        label: language("salesOrder.settlementStatusSettlementConfirm"),
        color: BadgeStyle.LightPrimary,
      },
    ],
    [
      SalesOrderSettlementStatus.SettlementCompleted,
      {
        label: language("salesOrder.settlementStatusSettlementComplete"),
        color: BadgeStyle.LightPrimary,
      },
    ],
  ]);
  return result.get(status);
};

/**
 * @description: 获取销售订单类型
 * @return string
 */
export const getSalesOrderTypeOptions = (
  status: number,
  language: CommonLanguageType
): string => {
  const result = new Map([
    [SalesOrderType.SalesOrder, language("salesOrder.orderTypeSalesOrder")],
    [
      SalesOrderType.WholesaleOrder,
      language("salesOrder.orderTypeWholesaleOrder"),
    ],
    [SalesOrderType.DepositOrder, language("salesOrder.orderTypeDepositOrder")],
    [SalesOrderType.ServiceOrder, language("salesOrder.orderTypeServiceOrder")],
  ]);
  return result.get(status) || "";
};

/**
 * @description: 获取销售订单履约状态（文本和样式）
 * @return CommonStatus
 */
export const getSalesOrderFulfillmentStatus = (
  status: NumberOrString,
  language: CommonLanguageType
): CommonStatus => {
  let str = "",
    statusClass = "";
  switch (status) {
    case String(SalesOrderFulfillmentStatus.Reserved):
      str = language("salesOrder.fulfillmentStatusReserved");
      statusClass = BadgeStyle.LightSuccess;
      break;
    case String(SalesOrderFulfillmentStatus.Reserving):
      str = language("salesOrder.fulfillmentStatusReserving");
      statusClass = BadgeStyle.LightWarning;
      break;
    case String(SalesOrderFulfillmentStatus.Pending):
      str = language("salesOrder.fulfillmentStatusPending");
      statusClass = BadgeStyle.LightPrimary;
      break;
    default:
      statusClass = BadgeStyle.LightPrimary;
  }
  return { str, statusClass };
};

/**
 * @description: 获取销售订单履约状态
 * @return TaggingItem[]
 */
export const getSalesOrderFulfillmentStatusOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("salesOrder.fulfillmentStatusReserved"),
      value: String(SalesOrderFulfillmentStatus.Reserved),
    },
    {
      label: language("salesOrder.fulfillmentStatusReserving"),
      value: String(SalesOrderFulfillmentStatus.Reserving),
    },
    {
      label: language("salesOrder.fulfillmentStatusPending"),
      value: String(SalesOrderFulfillmentStatus.Pending),
    },
  ];
};

/**
 * @description: 获取销售订单状态（文本和样式）
 * @return CommonStatus
 */
export const getSalesOrderDepositPaymentStatus = (
  status: number,
  language: CommonLanguageType
): CommonStatus => {
  let str = "",
    statusClass = "";
  switch (status) {
    case SalesOrderDepositPaymentStatus.Pending:
      // case SalesOrderState.New:
      str = language("salesOrder.paymentStatusPending");
      statusClass = BadgeStyle.LightPrimary; //黄
      break;
    case SalesOrderDepositPaymentStatus.Paid:
      str = language("salesOrder.paymentStatusPaid");
      statusClass = BadgeStyle.LightSuccess; //黄
      break;
  }
  return { str, statusClass };
};

/**
 * @description: 获取RFM等级说明
 * @return {*}
 */
export const getSalesOrderRFMMap = (
  status: string,
  language: CommonLanguageType
): string => {
  const result = new Map([
    [
      String(SalesOrderDistributionRFM.GeneralRetention),
      language("salesOrderOverview.rfmGeneralRetention"),
    ],
    [
      String(SalesOrderDistributionRFM.GenerallyKeep),
      language("salesOrderOverview.rfmGenerallyKeep"),
    ],
    [
      String(SalesOrderDistributionRFM.GeneralGrowing),
      language("salesOrderOverview.rfmGeneralGrowing"),
    ],
    [
      String(SalesOrderDistributionRFM.GeneralValue),
      language("salesOrderOverview.rfmGeneralValue"),
    ],
    [
      String(SalesOrderDistributionRFM.ImportantRetention),
      language("salesOrderOverview.rfmImportantRetention"),
    ],
    [
      String(SalesOrderDistributionRFM.ImportantKeep),
      language("salesOrderOverview.rfmImportantKeep"),
    ],
    [
      String(SalesOrderDistributionRFM.ImportantGrowing),
      language("salesOrderOverview.rfmImportantGrowing"),
    ],
    [
      String(SalesOrderDistributionRFM.ImportantValue),
      language("salesOrderOverview.rfmImportantValue"),
    ],
  ]);
  return result.get(status) || "";
};

/**
 * @description: 获取优先级等级图标
 * @return {*}
 */
export const getSalesOrderPriorityLevelMap = (
  status: string
): string | undefined => {
  const result = new Map([
    [String(SalesOrderPriorityLevel.Level1), "media/svg/custom/priority1.svg"],
    [String(SalesOrderPriorityLevel.Level2), "media/svg/custom/priority2.svg"],
    [String(SalesOrderPriorityLevel.Level3), "media/svg/custom/priority3.svg"],
    [String(SalesOrderPriorityLevel.Level4), "media/svg/custom/priority4.svg"],
    [String(SalesOrderPriorityLevel.Level5), "media/svg/custom/priority5.svg"],
  ]);
  return result.get(status);
};

/**
 * @description: 获取优先级等级图标样式
 * @return {*}
 */
export const getSalesOrderPriorityLevelSvgStyleMap = (
  status: string
): SvgStyle | undefined => {
  const result = new Map([
    [String(SalesOrderPriorityLevel.Level1), SvgStyle.Info],
    [String(SalesOrderPriorityLevel.Level2), SvgStyle.Info],
    [String(SalesOrderPriorityLevel.Level3), SvgStyle.Info],
    [String(SalesOrderPriorityLevel.Level4), SvgStyle.Info],
    [String(SalesOrderPriorityLevel.Level5), SvgStyle.Info],
  ]);
  return result.get(status);
};

/**
 * @description: 获取销售订单支付状态（文本和样式）
 * @return CommonStatus
 */
export const getSalesOrderPaymentStatus = (
  status: string,
  language: CommonLanguageType
): CommonStatus => {
  let str = "",
    statusClass = "",
    textClass = "";
  switch (status) {
    case SalesOrderPaymentStatus.PartialPaid:
      str = language("salesOrder.paymentStatusPartialPaid");
      statusClass = BadgeStyle.LightPrimary; //蓝
      textClass = TextStyle.Primary;
      break;
    case SalesOrderPaymentStatus.UnPaid:
      str = language("salesOrder.paymentStatusUnPaid");
      statusClass = BadgeStyle.LightDanger; // 红
      textClass = TextStyle.Danger;
      break;
    case SalesOrderPaymentStatus.Paid:
      str = language("salesOrder.paymentStatusPaid");
      statusClass = BadgeStyle.LightSuccess; // 绿
      textClass = TextStyle.Success;
      break;
    default:
      statusClass = BadgeStyle.LightPrimary;
      textClass = TextStyle.Primary;
  }
  return { str, statusClass, textClass };
};

/**
 * @description: 获取是否接受部分发货选项
 * @return TaggingItem[]
 */
export const getPartialShipmentOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("salesOrder.partialShipmentWholeOrderShipment"),
      value: String(PartialShipment.WholeOrderShipment),
    },
    {
      label: language("salesOrder.partialShipmentPartialShipment"),
      value: String(PartialShipment.PartialShipment),
    },
  ];
};

/**
 * @description: 获取销售订单履约状态
 * @return Map
 */
export const getPartialShipmentMap = (
  language: CommonLanguageType
): Map<string, string> => {
  const result = new Map([
    [
      String(PartialShipment.WholeOrderShipment),
      language("salesOrder.partialShipmentWholeOrderShipment"),
    ],
    [
      String(PartialShipment.PartialShipment),
      language("salesOrder.partialShipmentPartialShipment"),
    ],
  ]);
  return result;
};

/**
 * @description: 获取支付人海关额度（radio）
 * @return TaggingItem[]
 */
export const getMbAnnualLimitVerificationRadio = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("salesOrder.mbAnnualLimitVerificationVerified"),
      value: MbAnnualLimitVerification.Verified,
    },
    {
      label: language("salesOrder.mbAnnualLimitVerificationRisk"),
      value: MbAnnualLimitVerification.Risk,
    },
    {
      label: language("salesOrder.mbAnnualLimitVerificationExceed"),
      value: MbAnnualLimitVerification.Exceed,
    },
  ];
};

/**
 * @description: 获取质检报告选项
 * @return TaggingItem[]
 */
export const getQaReportOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("salesOrder.qaReportNotRequried"),
      value: String(QaReport.SystemStandard),
    },
    {
      label: language("salesOrder.qaReportRequried"),
      value: String(QaReport.Requried),
    },
  ];
};

/**
 * @description: 获取是否接受拆包邮寄选项
 * @return TaggingItem[]
 */
export const getPartialWholeShipmentOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("salesOrder.partialWholeshipmentPartial"),
      value: String(PartialWholeShipment.Partial),
    },
    {
      label: language("salesOrder.partialWholeshipmentWholeshipment"),
      value: String(PartialWholeShipment.WholeShipment),
    },
  ];
};

/**
 * @description: 获取清关模式
 * @return string
 */
export const getCustomerClearanceModeMap = (status: string): string => {
  const result = new Map([
    [String(CustomerClearanceMode.DDP1210), "保税仓模式"],
    [String(CustomerClearanceMode.DDP9610), "跨境零售模式"],
    [String(CustomerClearanceMode.DDPCC), "个人行邮"],
    [String(CustomerClearanceMode.DDPGeneralTrade), "一般贸易"],
    [String(CustomerClearanceMode.DAP), "目的地交货"],
  ]);
  return result.get(status) || "";
};

/**
 * @description: 获取优先对象选项
 * @return TaggingItem[]
 */
export const getPriorityHandlingOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("salesOrder.priorityHandlingWholeOrder"),
      value: String(PriorityHandling.WholeOrder),
    },
    {
      label: language("salesOrder.priorityHandlingPartialOrder"),
      value: String(PriorityHandling.PartialOrder),
    },
  ];
};

/**
 * @description: 获取优先对象选项(废弃)
 * @return TaggingItem[]
 */
export const getSettlementTypeOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("salesOrder.standardSettlement"),
      value: String(SettlementType.StandardSettlement),
    },
    {
      label: language("salesOrder.preSettlement"),
      value: String(SettlementType.PreSettlement),
    },
  ];
};
