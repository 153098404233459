/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-10-06 10:06:19
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-02-05 18:12:58
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getOrderAssignList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/routing/index",
      params
    );
  },
  getOrderAssignItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/routing/get-fulfillment",
      params
    );
  },
  quickRoutingItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/routing/create-fulfillment",
      params
    );
  },
  sendRequestItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/routing/send-request",
      params
    );
  },
  refuseOrderAssignItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/routing/refuse-fulfillment",
      params
    );
  },
  getOrderAssignItemReturn(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/order-item-return-index",
      params
    );
  },
  returnOrderAssignItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/routing/order-item-return",
      params
    );
  },
  cancelOrderAssignItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/routing/order-item-cancel",
      params
    );
  },
  cancelOrderAssignItemV2(params) {
    return ApiService.post(
        Config.fulfillmentApiUrl + "/api/order-route/routing/v2/order-item-cancel",
        params
    );
  },
  getRequestFulfillmentList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/get-request-fulfillment-list",
      params
    );
  },
  storeRequestFulfillment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/merchant-request-store",
      params
    );
  },
  getProductSkuList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/get-product-sku-list",
      params
    );
  },
  exportFulfillmentRejectedItems(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/export-fulfillment-rejected-items",
      params,
      { responseType: "blob" }
    );
  },
  export(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/routing/download",
      params,
      { responseType: "blob" }
    );
  },
};
