/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-19 13:06:09
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-19 13:08:06
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getInventoryList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/inventory/index",
      params
    );
  },
  getInventoryOnHandSkuWarehouse(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/inventory/on-hand/sku/warehouse",
      params
    );
  },
};
