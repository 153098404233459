/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-19 12:36:50
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-19 14:10:48
 * @FilePath: /fulfillment-frontend/src/directives/table.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Directive } from "vue";

const adjustColumnWidth = (el) => {
  const colgroup = el.querySelector("colgroup");
  const colDefs = [...colgroup.querySelectorAll("col")];
  colDefs.forEach((col) => {
    const clsName = col.getAttribute("name");
    const cells = [
      ...el.querySelectorAll(`td.${clsName}`),
      ...el.querySelectorAll(`th.${clsName}`),
    ];
    // 忽略加了"leave-alone"类的列
    if (cells[0]?.classList?.contains?.("leave-alone")) {
      return;
    }
    const widthList = cells.map((el) => {
      return el.querySelector(".cell")?.scrollWidth || 0;
    });
    const max = widthList.length > 0 ? Math.max(...widthList) : 0;
    const padding = 20;
    el.querySelectorAll(`col[name=${clsName}]`).forEach((element) => {
      element.setAttribute("width", max + padding);
    });
  });
  // 此段代码放入 adjustColumnWidth 内部最后面即可。
  const scrollingBody = el.querySelectorAll("div.is-scrolling-none");
  if (scrollingBody[0]?.classList?.contains?.("is-scrolling-none")) {
    scrollingBody[0].classList.remove("is-scrolling-none");
    scrollingBody[0].classList.add("is-scrolling-left");
    scrollingBody[0].style.overflowX = "auto";
  }
};

export const table: Directive = {
  mounted(el) {
    setTimeout(() => {
      adjustColumnWidth(el);
    }, 300);
  },
  updated(el) {
    // el.classList.add("r-table");
    setTimeout(() => {
      adjustColumnWidth(el);
    }, 300);
  },
};
