/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-10 09:40:36
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-17 14:22:57
 * @FilePath: /fulfillment-frontend/src/core/api/documents-invoice.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getFulfillmentApiUrl() {
    return Config.fulfillmentApiUrl;
  },
  getInvoiceList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/document/proforma-invoice/list",
      params
    );
  },
  addInvoice(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/document/proforma-invoice/store",
      params
    );
  },
  addDelivery(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/document/delivery/store",
      params
    );
  },
  addDeliveryAgent(params){
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/document/delivery/agent-store",
      params
    );
  },
  printProformaInvoice(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/document/proforma-invoice/download",
      params,
      { responseType: "blob" }
    );
  },
  printCommercialInvoice(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/billing/download",
      params,
      { responseType: "blob" }
    );
  },
  printDeliveryNote(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/document/proforma-invoice/download",
      params,
      { responseType: "blob" }
    );
  },
  // OS物流
  printPackingList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/document/proforma-invoice/download-packing-list",
      params,
      { responseType: "blob" }
    );
  },
  // EU和CN物流
  downloadPackingList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/document/download-packing-list",
      params,
      { responseType: "blob" }
    );
  },
  printStockOutList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/document/proforma-invoice/download-stock-out-label",
      params,
      { responseType: "blob" }
    );
  },
  //
  previewProformaInvoice(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/document/proforma-invoice/get-pdf-url",
      params,
      { responseType: "arraybuffer" }
    );
  },
};
