import { createI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import enLang from "@/core/language/en";
import zhLang from "@/core/language/zh";
import deLang from "@/core/language/de";

const messages = {
  en_US: enLang,
  zh_CN: zhLang,
  de_DE: deLang,
};

const i18n = createI18n({
  legacy: false,
  locale: JwtService.getLang() as string,
  fallbackLocale: "en_US",
  globalInjection: true,
  messages,
});

export default i18n;
