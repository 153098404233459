/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-10-20 18:28:06
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-06 13:03:23
 * @FilePath: /fulfillment-frontend/src/store/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import AliveModule from "@/store/modules/AliveModule";
import ConfigModule from "@/store/modules/ConfigModule";
import MenuModule from "@/store/modules/MenuModule";
import PageListModule from "@/store/modules/PageListModule";
import WindowModule from "@/store/modules/WindowModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    AliveModule,
    ConfigModule,
    MenuModule,
    PageListModule,
    WindowModule,
  },
});

export default store;
