import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getShipmentList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/index",
      params
    );
  },
  addShipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/store",
      params
    );
  },
  showShipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/show",
      params
    );
  },
  checkMerge(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/shipment-merge",
      params
    );
  },
  print(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/export-shipment-detail",
      params,
      { responseType: "blob" }
    );
  },
  exportOverseaShipmentReport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/export-shipment-detail",
      params,
      { responseType: "blob" }
    );
  },
  exportLogisticsDetailsReport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/download-logistic",
      params,
      { responseType: "blob" }
    );
  },
  exportOverseasShipmentTracking(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/tms/shipment/download-overseas-shipment-tracking",
      params,
      { responseType: "blob" }
    );
  },
  exportOutboardReport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/download-outboard-report",
      params,
      { responseType: "blob" }
    );
  },
  printCheckList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/print-packing-list",
      params,
      { responseType: "blob" }
    );
  },
  deliveredShipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/delivered",
      params
    );
  },
  updateShipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/update",
      params
    );
  },
  getShipmentServiceProfileList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/data-source/logistics-service-profile-shipment-option",
      params
    );
  },
  getShipmentLogs(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/logs",
      params
    );
  },
  getShipmentItemDetail(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/get-shipment-item-detail",
      params
    );
  },
  updateShipmentItemDetail(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/tms/shipment/update-shipment-item-detail",
      params
    );
  },
  addShipmentItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/add-shipment-item",
      params
    );
  },
  deleteShipmentItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/delete-shipment-item",
      params
    );
  },
  getShipmentCustomsHandlingOption(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/data-source/customs-handling-option",
      params
    );
  },
  getShipmentItems(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/show-items",
      params
    );
  },
  uploadUrl() {
    return Config.fulfillmentApiUrl + "/api/tms/shipment/upload";
  },
  getFile(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/common/file/get",
      params,
      { responseType: "blob" }
    );
  },
};
