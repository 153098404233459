/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-10-20 18:28:06
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-15 13:56:42
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getStockInList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-in/index",
      params
    );
  },
  addStockIn(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-in/store",
      params
    );
  },
  showStockIn(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-in/show",
      params
    );
  },
  updateStockIn(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-in/update",
      params
    );
  },
  entryStockIn(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-in/entry",
      params
    );
  },
  getStockOutList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-out/index",
      params
    );
  },
  showStockOut(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-out/show",
      params
    );
  },
  updateStockOut(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-out/update",
      params
    );
  },
  getPreStockOutList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-out/get-pre-stock-out-list",
      params
    );
  },
  searchPimProduct(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/stock-in/search-pim-product",
      params
    );
  },
};
