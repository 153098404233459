/*
 * @Author: GaoXiang0403 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2023-06-22 14:34:22
 * @LastEditors: GaoXiang0403 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2023-06-22 14:39:31
 * @FilePath: /fulfillment-frontend/src/directives/clickOutSide.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { Directive } from "vue";

export const clickOutSide: Directive = {
  beforeMount(el, binding) {
    function documentHandler(e: any) {
      //   console.log(e);
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) return false;
      // 判断指令中是否绑定了函数
      //   console.log("判断指令中是否绑定了函数", binding);
      if (binding.value) {
        // console.log("有绑定函数，则执行函数");
        // 有绑定函数，则执行函数
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unmounted中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  unmounted(el) {
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};
