import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getPaymentList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/payment/index",
      params
    );
  },
  addPayment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/payment/store",
      params
    );
  },
  showPayment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/payment/show",
      params
    );
  },
  updatePayment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/payment/update",
      params
    );
  },
  cancelPayment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/payment/cancel",
      params
    );
  },
};
