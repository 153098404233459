/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-03-06 11:02:37
 * @LastEditors: GaoXiang0403 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2023-06-23 15:23:27
 * @FilePath: /fulfillment-frontend/src/core/api/merchant.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";
import store from "@/store";

export default {
  getCompanyList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/index",
      params
    );
  },
  addCompany(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/store",
      params
    );
  },
  updateCompany(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/update",
      params
    );
  },
  deleteCompany(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/delete",
      params
    );
  },
  getCompanyInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/show",
      params
    );
  },

  getFiscalHistoryList(params?) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/index",
      params
    );
  },
  addFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/store",
      params
    );
  },
  updateFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/update",
      params
    );
  },
  deleteFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/delete",
      params
    );
  },
  getFiscalHistoryInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/show",
      params
    );
  },

  getMerchantPimData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/pim/show",
      params
    );
  },
  updateMerchantPimData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/pim/update",
      params
    );
  },
  getSalesChannelsData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/channel",
      params
    );
  },
  getProfitRateData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/profit-rate",
      params
    );
  },
};
