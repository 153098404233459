/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-07 11:30:15
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-01-19 16:14:45
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getWarehouseManagementModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setWarehouseManagementName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setWarehouseManagementName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/warehouse-management/";
  result.parentName = language("common.warehouseManagement");
  if (route.path.match("inbound")) {
    strName = language("common.inbound");
    listPath += "inbound";
  } else if (route.path.match("inspection")) {
    strName = language("common.inspection");
    listPath += "inspection";
  } else if (route.path.match("warehouse-inventory")) {
    strName = language("common.warehouseInventory");
    listPath += "warehouse-inventory";
  } else if (route.path.match("stock-in")) {
    strName = language("common.stockIn");
    listPath += "stock-in";
  } else if (route.path.match("stock-out")) {
    strName = language("common.stockOut");
    listPath += "stock-out";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getWarehouseManagementDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "warehouse-management-inspection-overview":
      result.currentName = language("common.overview");
      setWarehouseManagementName(language, route, result, router);
      break;
    case "warehouse-management-stock-in-information":
    case "warehouse-management-stock-out-information":
      result.currentName = language("common.information");
      setWarehouseManagementName(language, route, result, router);
      break;
    case "warehouse-management-inspection-inspections":
      result.currentName = language("inspection.inspections");
      setWarehouseManagementName(language, route, result, router);
      break;
    case "warehouse-management-inspection-logs":
      result.currentName = language("common.logs");
      setWarehouseManagementName(language, route, result, router);
      break;
  }
  return result;
};
