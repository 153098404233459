import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getSalesInvoiceList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-invoice/index",
      params
    );
  },
  print(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-invoice/print",
      params,
      { responseType: "blob" }
    );
  },
  print2(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-invoice/print",
      params
    );
  },
};
