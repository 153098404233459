/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-02 13:53:01
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-12 19:21:17
 * @FilePath: /fulfillment-frontend/src/store/enums/StoreEnums.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  LOGIN = "login",
  LOGOUT = "logout",
  VERIFY_AUTH = "verifyAuth",
  CHECK_TOKEN = "checkToken",
  CHECK_PERMISSION = "checkPermission",
  REFRESH_TOKEN = "refreshToken",
  GET_MENU_ITEMS = "getMenuItems",
  GET_OWNER_SWITCH = "getOwnerSwitch",
  UPDATE_ACTION_PERMITS = "updateActionPermits",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  ADD_KEEPALIVE_ACTION = "addKeepAliveAction",
  DEL_KEEPALIVE_ACTION = "delKeepAliveAction",
  CLEAR_KEEPALIVE_ACTION = "clearKeepAliveAction",
  GET_PAGE_CONFIG = "getPageConfig",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_TOKEN = "setToken",
  SET_USER = "setUser",
  SET_OWNER = "setOwner",
  SET_ERROR = "setError",
  SET_MENU_ITEMS = "setMenuItems",
  SET_ACTION_PERMITS = "setActionPermits",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  ADD_KEEPALIVE_MUTATION = "addKeepAliveMutation",
  DEL_KEEPALIVE_MUTATION = "delKeepAliveMutation",
  CLEAR_KEEPALIVE_MUTATION = "clearKeepAliveMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_PAGE_CONFIG = "setPageConfig",
  SET_CURRENT_PAGE_CONFIG = "setCurrentPageConfig",
  SET_WINDOW_IS_MOBILE = "setWindowIsMobile",
}

export { Actions, Mutations };
