import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getChanelList(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/channel/index",
      params
    );
  },
  addChannel(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/channel/store",
      params
    );
  },
  updateChannel(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/channel/update",
      params
    );
  },
  deleteChannel(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/channel/delete",
      params
    );
  },
  getChannelInfo(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/channel/show",
      params
    );
  },
  getDataSource(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/channel",
      params
    );
  },
  getDistributionDataSource(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/distribution-channels",
      params
    );
  },
};
