
import { currencyData } from "@/core/directive/interface/common";
import { defineComponent } from "vue";
import { CurrencyInputOptions, useCurrencyInput } from "vue-currency-input";

export default defineComponent({
  name: "ElCurrencyInput",
  props: {
    modelValue: {
      type: Number,
    },
    options: {
      type: Object as () => CurrencyInputOptions,
      default: () => {
        return currencyData;
      },
    },
  },
  setup(props) {
    const { inputRef, formattedValue } = useCurrencyInput(
      props.options as CurrencyInputOptions
    );

    return { inputRef, formattedValue };
  },
});
