import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import router from "@/router";
import { ApiBase } from "@/core/api";
import { decryptData } from "@/core/helpers/utils";

interface MenuItem {
  heading?: string;
  route?: string;
  base_url?: string;
  level: number;
  belong: string;
  pages: Array<Page>;
  permits?: Array<string>;
}

interface Page {
  heading: string;
  route: string;
  level: string;
  svgIcon?: string;
  fontIcon?: number;
  pages?: Array<Page>;
  permits?: Array<string>;
}

interface MenuItems {
  menuItems: Array<MenuItem>;
}
@Module
export default class MenuModule extends VuexModule implements MenuItems {
  menuItems = [];
  actionPermits: Array<string> = [];

  /**
   * Get config of menu
   * @returns MenuItems
   */
  get menuConfig() {
    return this.menuItems;
  }

  get routeActionPermits() {
    return this.actionPermits;
  }

  @Action({ rawError: true })
  [Actions.GET_MENU_ITEMS]({ path, callback }) {
    const flag = window.localStorage.getItem("menuIdentifier");
    const menuConfig = window.localStorage.getItem("menuConfig");
    try {
      const decryptedData = decryptData(
        window.localStorage.getItem("menuConfig")
      );
      if (flag && menuConfig) {
        const items = JSON.parse(decryptedData);
        this.context.commit(Mutations.SET_MENU_ITEMS, items);
        this.context.dispatch(Actions.CHECK_PERMISSION, path);
        this.context.dispatch(Actions.UPDATE_ACTION_PERMITS, path);
      }
    } catch (error) {
      console.error(error);
      window.localStorage.removeItem("menuConfig");
    }
    ApiBase.getMenuConfig()
      .then(({ data }) => {
        const jsonData = JSON.parse(decryptData(data.data.encrypted));
        if (data.code == 0) {
          if (flag == data.data.identifier && menuConfig) {
            callback();
            return;
          }
          window.localStorage.setItem("menuIdentifier", data.data.identifier);
          window.localStorage.setItem("menuConfig", data.data.encrypted);
          this.context.commit(Mutations.SET_MENU_ITEMS, jsonData);
          this.context.dispatch(Actions.CHECK_PERMISSION, path);
          this.context.dispatch(Actions.UPDATE_ACTION_PERMITS, path);
          callback();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Action({ rawError: true })
  [Actions.CHECK_PERMISSION](path) {
    const publicPath = [
      "/dashboard",
      "/sign-in",
      "/404",
      "/500",
      "/profile/overview",
      "/profile/settings",
      "/style-guide",
    ];
    if (publicPath.includes(path)) {
      return;
    }
    const pages: Array<MenuItem | Page> = [];
    const toFlatArray = (arr: Array<MenuItem> | Array<Page>) => {
      arr.forEach((item: Page | MenuItem) => {
        if (Array.isArray(item.pages)) {
          toFlatArray(item.pages);
        } else {
          pages.push(item);
        }
      });
      return pages;
    };
    const routeArray = toFlatArray(this.menuItems);
    const index = routeArray.findIndex((item) => {
      return path.indexOf(item.route) > -1;
    });
    if (index == -1) {
      console.log("No permission!");
      router.replace({ path: "/404" });
    }
  }

  @Action({ rawError: true })
  [Actions.UPDATE_ACTION_PERMITS](path) {
    const pages: Array<MenuItem | Page> = [];
    const toFlatArray = (arr: Array<MenuItem> | Array<Page>) => {
      arr.forEach((item: Page | MenuItem) => {
        if (Array.isArray(item.pages)) {
          toFlatArray(item.pages);
        } else {
          pages.push(item);
        }
      });
      return pages;
    };
    const routeArray = toFlatArray(this.menuItems);
    const currentPage: Array<MenuItem | Page> = routeArray.filter((item) => {
      return path.indexOf(item.route) > -1;
    });
    this.context.commit(
      Mutations.SET_ACTION_PERMITS,
      currentPage[0].permits ? currentPage[0].permits : []
    );
  }

  @Mutation
  [Mutations.SET_MENU_ITEMS](payload): void {
    this.menuItems = payload;
  }

  @Mutation
  [Mutations.SET_ACTION_PERMITS](payload): void {
    this.actionPermits = payload;
  }
}
