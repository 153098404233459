import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getQualityInspectionList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/warehouse/quality-inspection/index",
      params
    );
  },
  addQualityInspection(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/warehouse/quality-inspection/store",
      params
    );
  },
  deleteQualityInspection(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/warehouse/quality-inspection/delete",
      params
    );
  },
  showQualityInspection(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/warehouse/quality-inspection/show",
      params
    );
  },
  updateQualityInspection(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/warehouse/quality-inspection/update",
      params
    );
  },
  uploadImage() {
    return (
      Config.fulfillmentApiUrl +
      "/api/basic/warehouse/quality-inspection/upload-image"
    );
  },
  deleteUploadImage(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/warehouse/quality-inspection/delete-upload-image",
      params
    );
  },
};
