import {
  PrinterConfigTaskDocumentContent,
  printerDefaultConfig,
} from "../interface/print";

let socket: WebSocket;

export const printConnect = () => {
  socket = new WebSocket("ws://localhost:13528");
  //如果是https的话，端口是13529
  //socket = new WebSocket('wss://localhost:13529');
  // 打开Socket
  socket.onopen = function (event) {
    // 监听消息
    socket.onmessage = function (event) {
      console.log("Client received a message", event);
    };
    // 监听Socket的关闭
    socket.onclose = function (event) {
      console.log("Client notified socket has closed", event);
    };
    // const request = getRequestObject("getPrinters");
    // socket.send(JSON.stringify(request));
    //printStart2(socket)
  };
};

export const printClose = () => {
  socket.close();
};

/***
 * 构造request对象
 */
const getRequestObject = (cmd: string) => {
  return Object.assign(printerDefaultConfig, {
    requestID: getUUID(8, 16),
    version: "1.0",
    cmd: cmd,
  });
};

/***
 * 获取自定义区数据以及模板URL
 * waybillNO 电子面单号
 */
const getCustomAreaData = async (waybillNO) => {
  //获取waybill对应的自定义区的JSON object，此处的ajaxGet函数是伪代码
  //   ajaxGet(waybillNO);
  const jsonObject = await waybillNO;
  return {
    templateURL: jsonObject.content.templateURL,
    data: jsonObject.content.data,
  };
};

/***
 * 获取电子面单Json 数据
 * waybillNO 电子面单号
 */
const getWaybillJson = async (waybillNO) => {
  //获取waybill对应的json object，此处的ajaxGet函数是伪代码
  //   ajaxGet(waybillNO);
  const jsonObject = await waybillNO;
  return jsonObject;
};
/**

/**
 * 请求打印机列表demo
 * */
//var request  = getRequestObject("getPrinters");
//webSocket.send(JSON.stringify(request));
/**
 * 弹窗模式配置打印机
 * */
//var request  = getRequestObject("printerConfig");
//webSocket.send(JSON.stringify(request));
/**
 * 打印电子面单
 * printer 指定要使用那台打印机
 * waybillArray 要打印的电子面单的数组
 */
export const printStart = (waybillArray, printer) => {
  const documents: any[] = [];
  for (let i = 0; i < waybillArray.length; i++) {
    const content: any[] = [];
    const waybillJson = getWaybillJson(waybillArray[i]);
    const customAreaData = getCustomAreaData(waybillArray[i]);
    content.push(waybillJson, customAreaData);
    const doc = {
      documentID: waybillArray[i],
      content: content,
    };
    documents.push(doc);
  }
  const request = Object.assign(getRequestObject("print"), {
    task: {
      taskID: getUUID(8, 10),
      preview: false,
      printer: printer,
      documents: documents,
    },
  });
  socket.send(JSON.stringify(request));
};

/***
 *
 * 获取请求的UUID，指定长度和进制,如
 * getUUID(8, 2)   //"01001010" 8 character (base=2)
 * getUUID(8, 10) // "47473046" 8 character ID (base=10)
 * getUUID(8, 16) // "098F4D35"。 8 character ID (base=16)
 *
 */
const getUUID = (len, radix) => {
  const chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid: string[] = [];
  radix = radix || chars.length;
  if (len) {
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r = 0;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
};
/**
 * 响应请求demo
 * */
/*websocket.onmessage = function(event){
var response = eval(event.data);
if (response.cmd == 'getPrinters') {
    getPrintersHandler(response);//处理打印机列表
} else if (response.cmd == 'printerConfig') {
    printConfigHandler(response);
}
};*/

export const printStart2 = (contents: PrinterConfigTaskDocumentContent[]) => {
  const request = {
    cmd: "print",
    requetID: getUUID(8, 10),
    version: "1.0",
    task: {
      taskID: getUUID(8, 10),
      preview: false,
      printer: "4BARCODE 4B-2054L",
      documents: [
        {
          documentID: "9890000076011",
          contents: contents,
          // contents: [
          //   //电子面单部分
          //   {
          //     templateURL:
          //       "https://cloudprint.cainiao.com/print/resource/getResource.json?resourceId=23567575&status=0",
          //     signature:
          //       "19d6f7759487e556ddcdd3d499af087080403277b7deed1a951cc3d9a93c42a7e22ccba94ff609976c5d3ceb069b641f541bc9906098438d362cae002dfd823a8654b2b4f655e96317d7f60eef1372bb983a4e3174cc8d321668c49068071eaea873071ed683dd24810e51afc0bc925b7a2445fdbc2034cdffb12cb4719ca6b7",
          //     data: {
          //       type: "REQUEST_PARENT_WINDOW_INFO",
          //       payload: null,
          //       guid: "220049458",
          //       brand_article_no: "H20911A5360068950999",
          //       brand: "MONCLER",
          //       name: "Moncler Maya Short Down Jacket-4",
          //       color: "Black",
          //       size: "4",
          //       ean: "8057151179217",
          //       shipment_id: "1005510",
          //       proforma_invoice: "TP-23-1000004",
          //       position: "1",
          //       ref: "****14415#2",
          //     },
          //   },
          // ],
        },
      ],
    },
  };
  // console.log(request);
  socket.send(JSON.stringify(request));
};
