/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-17 23:19:31
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-09-01 15:33:00
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getOrderRequestList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/index",
      params
    );
  },
  getOrderRequestInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/show",
      params
    );
  },
  getOrderRequestLogs(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/logs",
      params
    );
  },
  updateOrderRequest(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/update",
      params
    );
  },
  getOrderRequestItemInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/item-info",
      params
    );
  },
  deleteOrderRequestItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/delete",
      params
    );
  },
  confirmedOrderRequest(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/confirmed",
      params
    );
  },
  canceledOrderRequest(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/canceled",
      params
    );
  },
  shippedOrderRequest(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/shipped",
      params
    );
  },
  getOrderRequesV2tList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/index",
      params
    );
  },
  getOrderRequestV2Info(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/show",
      params
    );
  },
  getOrderRequestV2InitialInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/initial-data",
      params
    );
  },
  getOrderRequestItemV2Info(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/item-info",
      params
    );
  },
  updateOrderRequestV2(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/update",
      params
    );
  },
  updateOrderRequestV3(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v3/update",
      params
    );
  },
  updateOrderRequestV2InventoryNote(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/inventory-note",
      params
    );
  },
  saveOrderRequestV2Payment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/save-payment",
      params
    );
  },
  saveOrderRequestV2(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/save",
      params
    );
  },
  confirmedOrderRequestV2(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/confirmed",
      params
    );
  },
  confirmedOrderRequestV3(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v3/confirmed",
      params
    );
  },
  deleteOrderRequestV2(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/delete",
      params
    );
  },
  canceledOrderRequestV2(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/canceled",
      params
    );
  },
  orderRequestV2SelectAddress(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/select-address",
      params
    );
  },
  orderRequestV2SearchAddress(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/search-address",
      params
    );
  },
  orderRequestV2SearchSku(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/search-sku",
      params
    );
  },
  getOrderRequesV2InvoicetList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/invoice/index",
      params
    );
  },
  getOrderRequesV2InvoicetInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/invoice/show",
      params
    );
  },
  createOrderRequestV2Invoice(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/invoice/create",
      params
    );
  },
  updateOrderRequestV2Invoice(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/invoice/update",
      params
    );
  },
  deleteOrderRequestV2Invoice(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/invoice/delete",
      params
    );
  },
  getOrderRequestV2ShipmentList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/shipment/index",
      params
    );
  },
  createOrderRequestV2Shipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/shipment/create",
      params
    );
  },
  updateOrderRequestV2Shipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/shipment/update",
      params
    );
  },
  checkOrderRequestV2Shipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/shipment/check-tracking",
      params
    );
  },
  getOrderRequestV2ShipmentInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/shipment/show",
      params
    );
  },
  printPreviewUrl() {
    return (
      Config.fulfillmentApiUrl + "/api/wms/inspection/merchant-printed-sheet"
    );
  },
  exportOrderRequest(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/order-request/v2/export",
      params,
      { responseType: "blob" }
    );
  },
  exportConfirmedTrack(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/export-confirmed-track",
      params,
      { responseType: "blob" }
    );
  },
  exportIncomingShipments(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/export-incoming-shipments",
      params,
      { responseType: "blob" }
    );
  },
  importConfirmedTrack() {
    return (
      Config.fulfillmentApiUrl +
      "/api/fulfillment/order-request/import-confirmed-track"
    );
  },
  importIncomingShipments() {
    return (
      Config.fulfillmentApiUrl +
      "/api/fulfillment/order-request/import-incoming-shipments"
    );
  },
  uploadOrderRequestInvoiceUrl() {
    return (
      Config.fulfillmentApiUrl +
      "/api/fulfillment/order-request/v2/invoice/upload"
    );
  },
};
