import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FsLightbox = _resolveComponent("FsLightbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, { onPreviewImages: _ctx.previewImages }, null, 8, ["onPreviewImages"]),
    (_ctx.options.toggler)
      ? (_openBlock(), _createBlock(_component_FsLightbox, {
          key: 0,
          type: "image",
          openOnMount: true,
          sources: _ctx.options.sources,
          slide: _ctx.options.sourcesSlide,
          onOpen: _ctx.imageOpen,
          onClose: _ctx.imageClose
        }, null, 8, ["sources", "slide", "onOpen", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}