import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";
import store from "@/store";

export default {
  getMenuConfig() {
    return ApiService.post(Config.baseApiUrl + "/api/config/menu", {});
  },

  getCountryData() {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/global-data/country",
      {}
    );
  },

  getPhoneCodeData() {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/global-data/country-phone-code",
      {}
    );
  },

  getTaggingData(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/tagging/tag",
      params
    );
  },

  getTaggingGroupData(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/tagging/group",
      params
    );
  },

  getBrandSourceData(params) {
    return ApiService.post(Config.pimApiUrl + "/api/data-source/brand", params);
  },

  getCategorySourceData(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/data-source/category",
      params
    );
  },

  getMerchantSourceData(params) {
    // const idParams =
    //   store.getters.currentUser.user_managed_accounts.merchant.length > 0
    //     ? {
    //         id: store.getters.currentUser.user_managed_accounts.merchant,
    //       }
    //     : {};
    const idParams =
      store.getters.currentUser.user_type === 1
        ? {
            id: store.getters.currentUser.user_managed_accounts.merchant,
          }
        : {};
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/merchant",
      {
        ...idParams,
        ...params,
      }
    );
  },
  getMerchantCompanySourceData(params?) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/data-source/merchant/company",
      params
    );
  },
  getMerchantGroupSourceData() {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/data-source/merchant/group",
      {}
    );
  },

  getMerchantWarehouseSourceData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/merchant-warehouse",
      params
    );
  },

  getCustomerSourceData(params) {
    // const idParams =
    //   store.getters.currentUser.user_managed_accounts.customer.length > 0
    //     ? {
    //         id: store.getters.currentUser.user_managed_accounts.customer,
    //       }
    //     : {};
    const idParams =
      store.getters.currentUser.user_type === 1
        ? {
            id: store.getters.currentUser.user_managed_accounts.customer,
          }
        : {};
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/customers",
      {
        ...idParams,
        ...params,
      }
    );
  },
  getOcpsCustomerSourceData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/data-source/ocps-customers/users",
      params
    );
  },

  getUserSourceData(params) {
    return ApiService.post(Config.baseApiUrl + "/api/data-source/user", {
      status: 10,
      ...params,
    });
  },

  getAccountSourceData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/account/name",
      params
    );
  },

  getShelfSourceData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/shelf-list",
      params
    );
  },

  getInfluencerGroupSourceData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/account/influencer-group",
      params
    );
  },

  getProductData(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/product/get-product",
      params
    );
  },
  // 优化后的接口
  getProductsData(params) {
    // /api/product/get-products
    return ApiService.post(
      Config.pimApiUrl + "/api/product/get-products-brief",
      params
    );
  },
  getOcpsCustomersBillingAddress(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/data-source/ocps-customers/billing-address",
      params
    );
  },
  getOcpsCustomersShippingAddress(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/data-source/ocps-customers/shipping-address",
      params
    );
  },
  getProductOcpsStore(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/product/ocps-store",
      params
    );
  },
  getOcpsSearchProducts(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/product/ocps-search-products",
      params
    );
  },
  getOcpsOrderManagerInitData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-manager/create-order/init-data",
      params
    );
  },
  createOcpsOrderManager(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-manager/create-order/create",
      params
    );
  },
  packageCompanyCustomer(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/package/company-customer",
      params
    );
  },
  getFile(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/common/file/get",
      params,
      { responseType: "blob" }
    );
  },

  ossupload(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/common/file/upload-image",
      params
    );
  },

  getCustomerOperatingEntity(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/operating-entity/customer",
      params
    );
  },

  getWarehouseData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/warehouse",
      {
        active: 1,
        ...params,
      }
    );
  },

  getUserOperatingEntity(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/authorize/me/operating-entity",
      params
    );
  },

  getSsoApps(params) {
    return ApiService.post(Config.baseApiUrl + "/api/sso/apps", params);
  },

  getSsoAppUrl(url, params) {
    return ApiService.post(url, params);
  },
};
