import { Directive } from "vue";
import store from "@/store";

const checkPermission = (el, binding) => {
  const { value } = binding;
  if (value && value.auth instanceof Array) {
    const hasPermission = checkPermissionFlag(value);
    if (!hasPermission) {
      el && el.parentNode && el.parentNode.removeChild(el);
    }
  } else {
    throw new Error(
      "Need permission! Like v-auth={auth: ['view','add'], mode: 'or'}"
    );
  }
};

export const checkPermissionFlag = (binding, flag?) => {
  let hasPermission = false;
  const { mode, auth } = binding;
  const actionPermits = store.getters.routeActionPermits;
  if (mode == "or") {
    hasPermission = auth.some((item) => {
      return actionPermits.includes(item);
    });
  } else if (mode == "and") {
    hasPermission = auth.every((item) => {
      return actionPermits.includes(item);
    });
  } else {
    hasPermission = auth.some((item) => {
      return actionPermits.includes(item);
    });
  }
  console.log("Has permission: " + hasPermission);
  if (flag !== undefined) {
    return hasPermission && flag;
  }
  return hasPermission;
};

export const auth: Directive = {
  mounted(el, binding) {
    checkPermission(el, binding);
  },
  updated(el, binding) {
    checkPermission(el, binding);
  },
};
