/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-05-25 13:20:09
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-05-25 13:36:50
 * @FilePath: /fulfillment-frontend/src/directives/inputFilter.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { filterSpecialSymbols } from "@/core/directive/function/common";
import { Directive } from "vue";

const checkInputValue = (el, binding) => {
  let inputEl;
  if (el.tagName.toLowerCase() !== "input") {
    inputEl = el.children[0];
  } else {
    inputEl = el;
  }
  const { value } = binding;
  if (value) {
    const { mode } = value;
    if (mode == "special-symbols") {
      // 过滤特殊符号
      inputEl.value = filterSpecialSymbols(inputEl.value);
    } else {
      // 默认过滤特殊符号
      inputEl.value = filterSpecialSymbols(inputEl.value);
    }
  } else {
    // 默认过滤特殊符号
    inputEl.value = filterSpecialSymbols(inputEl.value);
  }
  inputEl.dispatchEvent(new Event("input"));
};

export const inputFilter: Directive = {
  mounted(el, binding) {
    checkInputValue(el, binding);
  },
  updated(el, binding) {
    checkInputValue(el, binding);
  },
};
