/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-07 11:30:15
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-07 13:12:07
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getMBOrderRoutingModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setMBOrderRoutingName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setMBOrderRoutingName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/mb-order-routing/";
  result.parentName = language("common.mbOrderRouting");
  if (route.path.match("routing-overview")) {
    strName = language("common.routingOverview");
    listPath += "routing-overview";
  } else if (route.path.match("fulfillment-order")) {
    strName = language("common.fulfillmentOrders");
    listPath += "fulfillment-orders";
  } else if (route.path.match("order-tracking")) {
    strName = language("common.orderTracking");
    listPath += "order-tracking";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getMBOrderRoutingDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "mb-order-routing-routing-overview":
      result.currentName = language("salesOrder.overview");
      setMBOrderRoutingName(language, route, result, router);
      break;
    case "mb-order-routing-fulfillment-order-overview":
      result.currentName = language("fulfillmentOrder.fulfillmentOverview");
      setMBOrderRoutingName(language, route, result, router);
      break;
    case "mb-order-routing-fulfillment-order-shipment":
      result.currentName = language("fulfillmentOrder.shipment");
      setMBOrderRoutingName(language, route, result, router);
      break;
    case "mb-order-routing-fulfillment-order-exceptions":
      result.currentName = language("fulfillmentOrder.exceptions");
      setMBOrderRoutingName(language, route, result, router);
      break;
    case "mb-order-routing-order-tracking-information":
      result.currentName = language("common.information");
      setMBOrderRoutingName(language, route, result, router);
      break;
    case "mb-order-routing-routing-overview-refund":
      result.currentName = language("orderRefund.refundOverview");
      setMBOrderRoutingName(language, route, result, router);
      break;
    case "mb-order-routing-routing-overview-return-refund":
      result.currentName = language("orderRefund.refundOverview");
      setMBOrderRoutingName(language, route, result, router);
      break;
  }
  return result;
};
