/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-07 11:30:15
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-20 16:12:22
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getDoubleSaleOrderModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setDoubleSaleOrderName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setDoubleSaleOrderName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/double-sale-order/";
  result.parentName = language("common.doubleSaleOrder");
  if (route.path.match("order-overview")) {
    strName = language("common.orderOverview");
    listPath += "order-overview";
  } else if (route.path.match("order-routing")) {
    strName = language("common.orderRouting");
    listPath += "order-routing";
  } else if (route.path.match("draft-orders")) {
    strName = language("common.doubleSaleOrderDraftOrders");
    listPath += "draft-orders";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getDoubleSaleOrderDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "double-sale-order-order-overview-overview":
    case "double-sale-order-draft-order-overview":
      result.currentName = language("salesOrder.overview");
      setDoubleSaleOrderName(language, route, result, router);
      break;
    case "double-sale-order-order-overview-sales-profilling":
    case "double-sale-order-draft-order-sales-profilling":
      result.currentName = language("salesOrder.salesProfilling");
      setDoubleSaleOrderName(language, route, result, router);
      break;
    case "double-sale-order-order-overview-fulfillment-overview":
      result.currentName = language("fulfillmentOrder.fulfillmentOverview");
      setDoubleSaleOrderName(language, route, result, router);
      break;
    case "double-sale-order-order-overview-order-tracking":
      result.currentName = language("salesOrder.orderTracking");
      setDoubleSaleOrderName(language, route, result, router);
      break;
    case "double-sale-order-order-overview-exception-handlings":
      result.currentName = language("common.exceptionHandling");
      setDoubleSaleOrderName(language, route, result, router);
      break;
    case "double-sale-order-order-overview-support-tickets":
      result.currentName = language("salesOrder.supportTickets");
      setDoubleSaleOrderName(language, route, result, router);
      break;
    case "double-sale-order-order-overview-import":
      result.currentName = language("salesOrder.importOrders");
      setDoubleSaleOrderName(language, route, result, router);
      break;
    case "double-sale-order-shipment-label":
    case "double-sale-order-shipment-label-list":
    case "double-sale-order-shipment-label-detail":
      result.currentName = language("shipmentLabel.shipmentLabelManagement");
      setDoubleSaleOrderName(language, route, result, router);
      break;
  }
  return result;
};

export const getDraftOrderRouteUrl = (route: RouteLocationNormalizedLoaded) => {
  if (route.path.match("/sales-order/draft-orders")) {
    return "/sales-order/draft-orders/";
  } else {
    return "/double-sale-order/draft-orders/";
  }
};
