/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 15:27:17
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-07 10:08:37
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getDocumentsModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setDocumentsName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setDocumentsName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/documents/";
  result.parentName = language("common.documents");
  if (route.path.match("proforma-invoice")) {
    strName = language("common.proformaInvoice");
    listPath += "proforma-invoice";
  } else if (route.path.match("delivery-note")) {
    strName = language("common.deliveryNote");
    listPath += "delivery-note";
  } else if (route.path.match("packaging-list")) {
    strName = language("common.packagingList");
    listPath += "packaging-list";
  } else if (route.path.match("inspection-report")) {
    strName = language("common.inspectionReport");
    listPath += "inspection-report";
  } else if (route.path.match("sales-invoice")) {
    strName = language("common.salesInvoice");
    listPath += "sales-invoice";
  } else if (route.path.match("credit-note")) {
    strName = language("common.creditNote");
    listPath += "credit-note";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getDocumentsDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "documents-inspection-report-information":
      result.currentName = language("common.information");
      setDocumentsName(language, route, result, router);
      break;
  }
  return result;
};
