import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  login(params) {
    return ApiService.post(Config.baseApiUrl + "/api/authorize/login", params);
  },

  verify() {
    return ApiService.post(Config.baseApiUrl + "/api/authorize/me", {});
  },

  refreshToken() {
    return ApiService.post(Config.baseApiUrl + "/api/authorize/refresh", {});
  },

  logout() {
    return ApiService.post(Config.baseApiUrl + "/api/authorize/logout", {});
  },

  addUser(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/store", params);
  },

  updateUser(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/update", params);
  },

  deleteUser(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/delete", params);
  },

  getUserInfo(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/show", params);
  },

  getUserList(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/index", params);
  },
};
