/*
 * @Descripttion:
 * @Author: yika
 * @Date: 2022-11-28 15:31:00
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-10 17:27:31
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getInspectionList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/index",
      params
    );
  },
  getInspectionFile(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/download-package-receive",
      params,
      { responseType: "blob" }
    );
  },
  getInspectionHeaderInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/show-header",
      params
    );
  },
  getInspectionItems(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/show-items",
      params
    );
  },
  getInspectionScanner(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/scanner",
      params
    );
  },
  uploadInspectionWarehouseImage(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/wms/inspection/upload-warehouse-quality-inspection-image",
      params
    );
  },
  saveInspectionPassedImage(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/wms/inspection/save-passed-inspection-image",
      params
    );
  },
  getInspectionScanInspection(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/scan-inspection",
      params
    );
  },
  InspectionItemCheck(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/check",
      params
    );
  },
  updateInspectionMismatched(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/mismatched-edit",
      params
    );
  },
  updateInspectionMismatchedToRetrunMerchant(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/mismatch-return-merchant",
      params
    );
  },
  updateInspectionMissingInspectionResult(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/wms/inspection/update-missing-inspection-result",
      params
    );
  },
  updateInspectionSubmitQualityInspection(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/wms/inspection/submit-quality-inspection",
      params
    );
  },
  updateInspectionSecondQualityInspection(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/wms/inspection/second-quality-inspection",
      params
    );
  },
  updateInspectionWaitingApproving(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/waiting-approving",
      params
    );
  },
  updateInspectionInspected(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/inspected",
      params
    );
  },
  getInspectionInspectionList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/get-inspection-list",
      params
    );
  },
  getInspectionDetail(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/get-inspection-detail",
      params
    );
  },
  getInspectionOriginCountry(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/get-origin-country",
      params
    );
  },
  getInspectionSkuByIdentityCodeInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/wms/inspection/get-sku-by-identity-code-info",
      params
    );
  },
  inspectionCheckingSubmit(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/inspect",
      params
    );
  },
  getInspectionWarehouseFailImage(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/wms/inspection/get-warehouse-quality-inspection-image",
      params
    );
  },
  updateInspectionStockIn(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/stock-in",
      params
    );
  },
  printedSheet(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/printed-sheet",
      params
    );
  },
  printedMerchantSheet() {
    return (
      Config.fulfillmentApiUrl + "/api/wms/inspection/merchant-printed-sheet"
    );
  },
  quickInspection(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/wms/inspection/first-quality-pass",
      params
    );
  },
};
