import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getWarehouseList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/warehouse/index",
      params
    );
  },
  addWarehouse(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/warehouse/store",
      params
    );
  },
  showWarehouse(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/warehouse/show",
      params
    );
  },
  updateWarehouse(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/warehouse/update",
      params
    );
  },
};
