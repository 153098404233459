/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-15 10:10:31
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-30 12:14:03
 */
import { Directive, createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import * as directives from "@/directives";

//imports for app initialization
//import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
//import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { InlineSvgIcon } from "magicbean-saas-common";
import CurrencyInput from "@/components/form/CurrencyInput.vue";

import "@/core/plugins/keenthemes";
import "bootstrap";
import accounting from "accounting-js";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Emitter = require("tiny-emitter");
const emitter = new Emitter();

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    // eslint-disable-next-line @typescript-eslint/ban-types
    $currency: any;
  }
}

const app = createApp(App);

Object.keys(directives).forEach((key) => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});

app.config.globalProperties.emitter = emitter;

app.config.globalProperties.$currency = {
  currency(
    val: string,
    precision: string,
    decimal: string,
    thousand: string,
    symbol: string
  ) {
    return accounting.formatMoney(val, {
      symbol: symbol,
      precision: precision,
      thousand: thousand,
      decimal: decimal,
    });
  },
};

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
//MockAdapter.init(app);
//initApexCharts(app);
initInlineSvg(app);
app.component("inline-svg-icon", InlineSvgIcon);
app.component("currency-input", CurrencyInput);
initVeeValidate();

app.use(i18n);

app.mount("#app");
