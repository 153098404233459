/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-07 11:30:15
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-07 13:56:33
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getDeclarationManagementModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setDeclarationManagementName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setDeclarationManagementName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/declaration-management/";
  result.parentName = language("common.declarationManagement");
  if (route.path.match("export-declaration")) {
    strName = language("common.exportDeclaration");
    listPath += "export-declaration";
  } else if (route.path.match("import-declaration")) {
    strName = language("common.importDeclaration");
    listPath += "import-declaration";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getDeclarationManagementDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "declaration-management-export-declaration-information":
      result.currentName = language("common.information");
      setDeclarationManagementName(language, route, result, router);
      break;
    case "declaration-management-export-declaration-log":
      result.currentName = language("common.logs");
      setDeclarationManagementName(language, route, result, router);
      break;
  }
  return result;
};
