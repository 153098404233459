/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-17 23:19:31
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-09-01 15:33:00
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getOrderRefundIndex(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/order-item-invoice-refund-index",
      params
    );
  },
  getOrderReturnRefundIndex(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/order-item-return-refund-index",
      params
    );
  },
  submitOrderRefund(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/order-item-invoice-refund",
      params
    );
  },
  submitOrderReturnRefund(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/order-item-return-refund",
      params
    );
  },
  getRefundablePaymentDocumentList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/order-refundable-query",
      params
    );
  },
  submitBatchRefund(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/routing/order-item-batch-refund",
      params
    );
  },
};
