/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-06 13:03:03
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-07 17:24:03
 * @FilePath: /fulfillment-frontend/src/store/modules/WindowModule.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import objectPath from "object-path";
import merge from "deepmerge";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";

interface WindowInterface {
  windowConfig: WindowConfig;
}

interface WindowConfig {
  isMobile: boolean;
}

const WindowDefaultConfig = {
  isMobile: false,
};

@Module
export default class WindowModule
  extends VuexModule
  implements WindowInterface
{
  config = this.windowConfig;

  /**
   * Get config of page
   * @returns windowConfig
   */
  get windowConfig() {
    return merge({}, WindowDefaultConfig);
  }

  get isMobile() {
    return this.config.isMobile;
  }

  @Mutation
  [Mutations.SET_WINDOW_IS_MOBILE](payload: boolean): void {
    this.config.isMobile = payload;
  }
}
