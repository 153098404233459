import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getExceptionsList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/exception-handling/exceptions/index",
      params
    );
  },
  addEventListener(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/exception-handling/exceptions/index",
      params
    );
  },
  showExceptions(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/exception-handling/exceptions/show",
      params
    );
  },
  updateExceptions(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/exception-handling/exceptions/update",
      params
    );
  },
  addExceptions(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/exception-handling/exceptions/store",
      params
    );
  },
};
