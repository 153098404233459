import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getLogisticsServiceProviderList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/logistics-service-provider/index",
      params
    );
  },
  addLogisticsServiceProvider(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/logistics-service-provider/store",
      params
    );
  },
  showLogisticsServiceProvider(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/logistics-service-provider/show",
      params
    );
  },
  updateLogisticsServiceProvider(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/logistics-service-provider/update",
      params
    );
  },
  addProfileShipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/logistics-service-provider/profile-shipment-options/store",
      params
    );
  },
  showProfileShipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/logistics-service-provider/profile-shipment-options/show",
      params
    );
  },
  updateProfileShipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/logistics-service-provider/profile-shipment-options/update",
      params
    );
  },
  showProfileShipmentlist(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/logistics-service-provider/profile-shipment-options/index",
      params
    );
  },
  deleteProfileShipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/logistics-service-provider/profile-shipment-options/delete",
      params
    );
  },
  getLogisticsServiceProviderData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/data-source/logistics-service-provider",
      {
        status: 10,
        ...params,
      }
    );
  },
};
