/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-05 10:27:57
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-05-10 16:53:30
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getSingleInventoryList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/inventory/single",
      params
    );
  },
  getAggregateInventoryList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/inventory/aggregate",
      params
    );
  },
  getInventoryPimSku(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/inventory/get-pim-sku",
      params
    );
  },
  updateInventory(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/inventory/update",
      params
    );
  },
  inventoryRefundToSpot(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/inventory/inventory-update",
      params
    );
  },
  exportAggregate(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/inventory/export-aggregate",
      params,
      { responseType: "blob" }
    );
  },
  exportSingle(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/inventory/export-single",
      params,
      { responseType: "blob" }
    );
  },
};
