/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-06-24 13:59:44
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-10-27 14:06:50
 */
const config = {
  baseApiUrl: process.env.VUE_APP_BASE_API_URL,
  merchantApiUrl: process.env.VUE_APP_MERCHANT_API_URL,
  distributionApiUrl: process.env.VUE_APP_DISTRIBUTION_API_URL,
  fulfillmentApiUrl: process.env.VUE_APP_FULFILLMENT_API_URL,
  settlementApiUrl: process.env.VUE_APP_SETTLEMENT_API_URL,
  pimApiUrl: process.env.VUE_APP_PIM_API_URL,
  baseUrl: process.env.VUE_APP_BASE_URL,
  merchantUrl: process.env.VUE_APP_MERCHANT_URL,
  distributionUrl: process.env.VUE_APP_DISTRIBUTION_URL,
  fulfillmentUrl: process.env.VUE_APP_FULFILLMENT_URL,
  settlementUrl: process.env.VUE_APP_SETTLEMENT_URL,
  pimUrl: process.env.VUE_APP_PIM_URL,
  cdnUrl: process.env.VUE_APP_CDN_URL,
};

export default config;
// staging：http://staging-api-merchant.magic-bean-service.com
