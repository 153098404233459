/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-30 15:27:36
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-01-29 11:05:30
 */
import { CurrencyInputOptions } from "vue-currency-input";
import { BadgeStyle, NumberOrString } from "../type/common";

export const currencyData: CurrencyInputOptions = {
  currency: "EUR",
};

export const defaultProfileData = {
  firstname: "",
  lastname: "",
  email: "",
  group_name: "",
  tecrm_guide_id: "",
  mobile_number: "",
};

export const defaultAddressData = {
  firstname: "",
  lastname: "",
  company: "",
  street: "",
  city: "",
  region: "",
  country: "",
  country_id: "",
  country_iso_2: "",
  postcode: "",
  zip_code: "",
  telephone: "",
};

export const defaultDoubleSaleAddressData = {
  address_type: "",
  firstname: "",
  lastname: "",
  company: "",
  telephone: "",
  telephone_code: "",
  country_iso_2: "",
  region: "",
  city: "",
  district: "",
  street: "",
  zip_code: "",
  primary_address: 1,
};

export interface CommonModuleRouter {
  currentName: string;
  parentName: string;
  parentPath: string;
  listName: string;
  listPath: string;
}

export interface TableHeader {
  name: string;
  key: string;
  sortable?: boolean;
  align?: string;
}

export interface FilterOption {
  field: string;
  value?: NumberOrString | string[];
  from?: string;
  to?: string;
  condition?: string;
}

export interface SortOption {
  field: string;
  direction: string;
}

export interface TaggingItem {
  label: string;
  value: NumberOrString;
}

export interface TaggingChildrenItem {
  label: string;
  value: NumberOrString;
  children: TaggingItem[];
}

export interface CommonStatus {
  str: string;
  statusClass: string;
  textClass?: string;
  progressClass?: string;
}

export interface WarehouseOption {
  label?: string;
  value: NumberOrString;
  address?: string;
  id?: NumberOrString;
  status?: string;
  owner?: string;
  name?: string;
  key?: string;
}

export interface SkuWarehouseOption {
  id: number;
  status: number;
  owner: number;
  name: string;
  qty: number;
  key: number;
}

export interface MerchantOption {
  label: string;
  value: NumberOrString;
  name: string;
  country_iso_2: string;
  address: string;
  entity_data: MerchantOptionEntityData;
}

interface MerchantOptionEntityData {
  commission: entityDataCommission;
  address: string;
  country_iso_2: string;
  name: string;
  __show: MerchantOptionEntityDataShow;
}

interface MerchantOptionEntityDataShow {
  country_iso_2: string;
  commission: string;
}

export interface ChannelOption {
  channel_type: string;
  label: string;
  sort: number;
  value: NumberOrString;
  entity_data: ChannelOptionEntityData;
}

interface ChannelOptionEntityData {
  commission: entityDataCommission;
  __show: ChannelOptionEntityDataShow;
}

interface entityDataCommission {
  max: string;
  min: string;
}

interface ChannelOptionEntityDataShow {
  commission: string;
}

export type AddressData = typeof defaultAddressData;

export type DoubleSaleAddressData = typeof defaultDoubleSaleAddressData;

export interface CustomerOption extends TaggingItem {
  profile: ProfileData;
}

export type ProfileData = typeof defaultProfileData;

export interface AddressOption {
  label: string;
  value: NumberOrString;
  data: AddressData;
}

export interface CommonMapResult {
  label: string;
  color: BadgeStyle;
}

export interface LogisticsServiceProviderOption extends TaggingItem {
  short_key: string;
  short_name: string;
}

export interface OcpsCustomerItem extends TaggingItem {
  profile: ProfileData;
}
