/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-01-31 10:08:25
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-01-31 13:05:56
 * @FilePath: /fulfillment-frontend/src/store/modules/PageListModule.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import objectPath from "object-path";
import merge from "deepmerge";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";

interface PageList {
  pageConfig: PageConfig;
}

interface PageConfig {
  pageSize: number;
}

const pageDefaultConfig: PageConfig = {
  pageSize: 10,
};

@Module
export default class PageListModule extends VuexModule implements PageList {
  config = this.pageConfig;

  /**
   * Get config of page
   * @returns pageSize
   */
  get pageConfig() {
    return merge({}, pageDefaultConfig);
  }

  /**
   * Get config of pageCurrent
   * @returns pageCurrentConfig
   */
  get currentConfig() {
    return (path, defaultValue) => {
      const pageConfig: any = JSON.parse(
        window.localStorage.getItem("pageConfig") || "{}"
      );
      return objectPath.get(pageConfig, path, defaultValue);
    };
  }

  /**
   * Get config of pageCurrent
   * @returns pageCurrentConfig
   */
  get currentConfigPageSize() {
    return (key) => {
      const pageConfig: any = JSON.parse(
        window.localStorage.getItem("pageConfig") || "{}"
      );
      let pageSize = this.pageConfig.pageSize;
      if (typeof pageConfig[key] !== "undefined") {
        pageSize = pageConfig[key].pageSize;
      }
      return pageSize;
    };
  }

  @Action
  [Actions.GET_PAGE_CONFIG]() {
    const pageConfig = JSON.parse(
      window.localStorage.getItem("pageConfig") || "{}"
    );
    return pageConfig;
  }

  @Mutation
  [Mutations.SET_PAGE_CONFIG](payload): void {
    window.localStorage.setItem("pageConfig", JSON.stringify(payload));
  }

  @Mutation
  [Mutations.SET_CURRENT_PAGE_CONFIG]({ key, data }): void {
    const pageConfig = JSON.parse(
      window.localStorage.getItem("pageConfig") || "{}"
    );
    if (typeof pageConfig[key] === "undefined") {
      pageConfig[key] = data;
    } else {
      pageConfig[key] = merge(pageConfig[key], data);
    }
    window.localStorage.setItem("pageConfig", JSON.stringify(pageConfig));
  }
}
