/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-06-24 13:59:44
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-08-25 15:20:31
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getCustomDeclarationExportList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/custom-declaration-export/index",
      params
    );
  },
  addCustomDeclarationExport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/custom-declaration-export/store",
      params
    );
  },
  showCustomDeclarationExport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/custom-declaration-export/show",
      params
    );
  },
  getCustomDeclarationLogs(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/custom-declaration-export/logs",
      params
    );
  },
  deliveredCustomDeclarationExport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/custom-declaration-export/delivered",
      params
    );
  },
  updateCustomDeclarationExport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/custom-declaration-export/update",
      params
    );
  },
  getShipmentServiceProfileList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/basic/data-source/logistics-service-profile-shipment-option",
      params
    );
  },
  getShipmentServiceProfileListLogs(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/shipment/logs",
      params
    );
  },
  uploadUrl() {
    return (
      Config.fulfillmentApiUrl + "/api/tms/custom-declaration-export/upload"
    );
  },
  exportReport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/tms/custom-declaration-export/export-report",
      params,
      { responseType: "blob" }
    );
  },
};
