import { CookieComponent } from "@/assets/ts/components";

const ID_TOKEN_KEY = "token" as string;
const ID_LANG = "lang" as string;

/**
 * @description get token form Cookie
 */
export const getToken = (): string | null => {
  if (process.env.NODE_ENV === "development") {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  } else {
    return CookieComponent.get(ID_TOKEN_KEY);
  }
};

/**
 * @description save token into Cookie
 * @param token: string
 */
export const saveToken = (token: string): void => {
  CookieComponent.set(ID_TOKEN_KEY, token, {
    domain: "magic-bean-service.com",
  });
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form Cookie
 */
export const destroyToken = (): void => {
  CookieComponent.delete(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description get language form Cookie
 */
export const getLang = (): string | null => {
  if (process.env.NODE_ENV === "development") {
    return window.localStorage.getItem(ID_LANG)
      ? window.localStorage.getItem(ID_LANG)
      : "en_US";
  } else {
    return CookieComponent.get(ID_LANG)
      ? CookieComponent.get(ID_LANG)
      : "en_US";
  }
};

/**
 * @description save language into Cookie
 * @param lang: string
 */
export const setLang = (lang: string): void => {
  CookieComponent.set(ID_LANG, lang, {
    domain: "magic-bean-service.com",
  });
  window.localStorage.setItem(ID_LANG, lang);
};

/**
 * @description remove language form Cookie
 */
export const destroyLang = (): void => {
  CookieComponent.delete(ID_LANG);
  window.localStorage.removeItem(ID_LANG);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getLang,
  setLang,
  destroyLang,
};
