/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-17 23:19:31
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-16 22:31:41
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getOrderTrackingList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/tracking/index",
      params
    );
  },
  getOrderTrackingInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/tracking/show",
      params
    );
  },
  delayOrderTracking(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/tracking/delay",
      params
    );
  },
  getOrderProgression(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/tracking/get-order-progression",
      params
    );
  },
  getOrderBatchTracking(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/tracking/get-batch-tracking",
      params
    );
  },
  exportProgress(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/tracking/export-progress",
      params,
      { responseType: "blob" }
    );
  },
  printProgress(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/order-route/tracking/print-progress",
      params,
      { responseType: "blob" }
    );
  },
  exportReport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/tracking/order-tracking-report",
      params,
      { responseType: "blob" }
    );
  },
  printReport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/order-route/tracking/print-order-tracking-report",
      params,
      { responseType: "blob" }
    );
  },
  importBatchDelay() {
    return Config.fulfillmentApiUrl + "/api/order-route/tracking/batch-delay";
  },
  getRequestShipmentTracking(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/order-request/v2/shipment/tracking",
      params
    );
  },
};
