import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface StoreInfo {
  aliveList: Array<string>;
}

@Module
export default class AliveModule extends VuexModule implements StoreInfo {
  aliveList: string[] = [];

  /**
   * Get keep alive components name
   * @returns array
   */
  get getAliveList(): Array<string> {
    return this.aliveList;
  }

  @Mutation
  [Mutations.ADD_KEEPALIVE_MUTATION](payload) {
    if (this.aliveList.indexOf(payload) === -1) {
      this.aliveList.push(payload);
    }
  }

  @Mutation
  [Mutations.DEL_KEEPALIVE_MUTATION](payload) {
    const index = this.aliveList.indexOf(payload);
    if (index > -1) {
      this.aliveList.splice(index, 1);
    }
  }

  @Mutation
  [Mutations.CLEAR_KEEPALIVE_MUTATION]() {
    this.aliveList = [];
  }

  @Action
  [Actions.ADD_KEEPALIVE_ACTION](payload) {
    this.context.commit(Mutations.ADD_KEEPALIVE_MUTATION, payload);
  }

  @Action
  [Actions.DEL_KEEPALIVE_ACTION](payload) {
    this.context.commit(Mutations.DEL_KEEPALIVE_MUTATION, payload);
  }

  @Action
  [Actions.CLEAR_KEEPALIVE_ACTION]() {
    this.context.commit(Mutations.CLEAR_KEEPALIVE_MUTATION);
  }
}
