/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-09-19 14:28:41
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-16 13:59:54
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getDraftOrderList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/index",
      params
    );
  },
  getDoubleSaleDraftOrderList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/ds-list",
      params
    );
  },
  getDoubleSaleDraftOrderItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/ds-item",
      params
    );
  },
  getDoubleSaleDraftOrderShow(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/ds-show",
      params
    );
  },
  addDraftOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/store",
      params
    );
  },
  getShowInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/show",
      params
    );
  },
  createDraftOrderGenerateOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/generate-order",
      params
    );
  },
  createDoubleSaleDraftOrderGenerateOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/draft-order/ds-generate-order",
      params
    );
  },
  updateDraftOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/update",
      params
    );
  },
  updateDoubleSaleDraftOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/ds-update",
      params
    );
  },
  deleteDoubleSaleDraftOrderProductItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/ds-item-delete",
      params
    );
  },
  deleteDraftOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/delete",
      params
    );
  },
  getOcpsSearchProducts(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/product/ocps-search-products",
      params
    );
  },
  getOrderManagerDraftOrderInitData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/init-data",
      params
    );
  },
  createDraftOrderOcpsSku(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/create-ocps-sku",
      params
    );
  },
  uploadDraftOrderFile(params) {
    // /api/fulfillment/draft-order/upload
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/batch-upload",
      params
    );
  },
  uploadSkuFile(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/upload",
      params
    );
  },
  exportDoubleSaleDraftOrderItems(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/draft-order/export-ds-items",
      params,
      { responseType: "blob" }
    );
  },
};
