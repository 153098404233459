/*
 * @Descripttion:
 * @Author: xiang.gao 1543865938
 * @Date: 2022-05-23 18:58:23
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-10-18 16:45:16
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getFulfillmentOrderData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/fulfillment-order/show",
      params
    );
  },
  getFulfillmentOrderDataItems(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/fulfillment-order/show-items",
      params
    );
  },
  updateFulfillmentOrderData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/fulfillment-order/update",
      params
    );
  },
  cancelFulfillmentOrderData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/fulfillment-order/void",
      params
    );
  },
  closeFulfillmentOrderData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/fulfillment-order/closed",
      params
    );
  },
  getWarehouseData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/warehouse",
      {
        active: 1,
        ...params,
      }
    );
  },
  confirmFulfillmentOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/fulfillment-order/confirmed",
      params
    );
  },
  rejectFulfillmentOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/fulfillment-order/rejected",
      params
    );
  },
  exportFulfillmentOrders(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/fulfillment-order/export-reservation",
      params,
      { responseType: "blob" }
    );
  },
  exportFulfillmentOrderOverview(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/fulfillment-order/export-fulfillment-detail",
      params,
      { responseType: "blob" }
    );
  },
  exportUnderstockEqualization(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/fulfillment-order/export-fulfillment-stock",
      params,
      { responseType: "blob" }
    );
  },
  exportPriorityEqualization(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/fulfillment-order/export-fulfillment-sku",
      params,
      { responseType: "blob" }
    );
  },
  getFulfillmentOrderItemDetail(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/fulfillment-order/item-detail",
      params
    );
  },
  addFulfillmentOrderShipment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/fulfillment-order/add-shipment",
      params
    );
  },
  getFulfillmentModeSource(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/performance-mode",
      params
    );
  },
};
