import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getSalesOrderList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/index",
      params
    );
  },
  getSalesOrderListItemInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/brief-info",
      params
    );
  },
  getFulfillmentOrderList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/fulfillment-order/index",
      params
    );
  },
  addCompany(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/merchant/company/store",
      params
    );
  },
  updateCompany(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/merchant/company/update",
      params
    );
  },
  deleteCompany(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/merchant/company/delete",
      params
    );
  },
  getCompanyInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/show-overview",
      params
    );
  },
  getSalesOrderInvoiceItems(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/show",
      params
    );
  },
  getSalesOrderInvoiceBy(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/get-invoice-by-order-number",
      params
    );
  },
  getSalesOrderSpecificationData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/show-specification",
      params
    );
  },
  getSalesOrderApprove(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/approve",
      params
    );
  },
  updateSalesOrderSpecification(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/update-specification",
      params
    );
  },
  verifiedSalesOrderSpecification(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/verified",
      params
    );
  },
  rejectedSalesOrderSpecification(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/rejected",
      params
    );
  },

  getCompanyChanelList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/merchant/company/channel/index",
      params
    );
  },

  getFiscalHistoryList(params?) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/index",
      params
    );
  },
  addFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/store",
      params
    );
  },
  updateFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/update",
      params
    );
  },
  deleteFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/delete",
      params
    );
  },
  getFiscalHistoryInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/show",
      params
    );
  },

  getMerchantPimData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/pim/show",
      params
    );
  },
  updateMerchantPimData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/pim/update",
      params
    );
  },

  getMerchantRelationshipList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/relation/index",
      params
    );
  },
  addMerchantRelationship(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/relation/store",
      params
    );
  },
  updateMerchantRelationship(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/relation/update",
      params
    );
  },
  deteleMerchantRelationship(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/relation/delete",
      params
    );
  },
  getMerchantRelationshipInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/relation/show",
      params
    );
  },
  getSalesOrderItems(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/items",
      params
    );
  },
  addFulfillmentOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/fulfillment-order/store",
      params
    );
  },
  getFulfillmentOrderItemsSpotInventory(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/items-spot-inventory",
      params
    );
  },
  export(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/export-sales-order",
      params,
      { responseType: "blob" }
    );
  },
  exportSku(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/export-sales-order-by-sku",
      params,
      { responseType: "blob" }
    );
  },
  exportMmaOrder(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/export-mma-order-item-list",
      params,
      { responseType: "blob" }
    );
  },
  exportDoubleSale(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/export-double-sales-order",
      params,
      { responseType: "blob" }
    );
  },
  exportDoubleSaleSku(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/export-double-sales-order-by-sku",
      params,
      { responseType: "blob" }
    );
  },
  exportSalesProfilling(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/export-sales-profiling",
      params,
      { responseType: "blob" }
    );
  },
  exportOrderCombination(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/export-order-combination",
      params,
      { responseType: "blob" }
    );
  },

  exportSalesOrderItemList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/export-order-item-list",
      params,
      { responseType: "blob" }
    );
  },
  exportSalesFinancial(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/export-sales-financial",
      params,
      { responseType: "blob" }
    );
  },
  exportCrossCheckReport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/cross-check-report",
      params,
      { responseType: "blob" }
    );
  },
  exportSettlementInvoiceReport(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/settlement-invoice-report",
      params,
      { responseType: "blob" }
    );
  },
  exportOrderNotice(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/sales-order/print-order-notice",
      params,
      { responseType: "blob" }
    );
  },
  uploadServiceOrdersExcel() {
    return Config.fulfillmentApiUrl + "/api/fulfillment/service-order/import";
  },
  getOrderConfigOptions(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/data-source/config/import-order-config-options",
      params
    );
  },
  importOrderManager() {
    return Config.fulfillmentApiUrl + "/api/order-manager/import-order/import";
  },
  uploadUrl() {
    return Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/upload";
  },
};
