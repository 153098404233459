/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-30 18:50:37
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-02-28 14:29:22
 */
export enum SalesOrderStatus { // 旧订单状态
  Pending = "Pending",
  PendingPayment = "Pending Payment",
  SellOnCredit = "Sell On Credit",
  Processing = "Processing",
  PartialShipped = "Partial Shipped",
  Shipped = "Shipped",
  Complete = "Complete",
  Refunded = "Refunded",
  Canceled = "Canceled",
  OnHold = "On Hold",
}

export enum SalesOrderNewStatus { // 新订单状态
  New = 10,
  Submit = 20,
  Verified = 40,
  Processing = 50,
  FulfillmentCompleted = 70,
  OrderCompleted = 80,
  // 特殊状态
  Canceled = 90,
  Refunded = 100,
  Closed = 110,
}

export enum SalesOrderSettlementStatus { // 新订单状态
  SettlementConfirmation = 30,
  SettlementCompleted = 60,
}

export enum SalesOrderDepositPaymentStatus { // 定金支付状态
  Pending = 1,
  Paid = 2,
}

export enum SalesOrderState {
  New = "new",
  Pending = "pending",
  PendingPayment = "pending_payment",
  SellOnCredit = "sell_on_credit",
  Processing = "processing",
  PartialShipped = "partial_shipped",
  Shipped = "shipped",
  Complete = "complete",
  Refunded = "refunded",
  Canceled = "canceled",
  PaymentReview = "payment_review",
  OnHold = "holded",
  Closed = "closed",
}

export enum SalesOrderType {
  SalesOrder = 2,
  WholesaleOrder,
  DepositOrder,
  ServiceOrder,
  Retail,
  Wholesale,
  Deposit,
  SalesAllocation,
  B2BReseller,
  C2B2C,
  FlashSale,
}

export enum SalesOrderFulfillmentStatus {
  Reserved = 1,
  Reserving,
  Pending,
}

export enum SalesOrderDistributionMode {
  GrossProfitMode = 10,
  GMVMode = 20,
}

export enum SalesOrderDistributionRFM {
  GeneralRetention = 10,
  GenerallyKeep = 20,
  GeneralGrowing = 30,
  GeneralValue = 40,
  ImportantRetention = 50,
  ImportantKeep = 60,
  ImportantGrowing = 70,
  ImportantValue = 80,
}

export enum SalesOrderPriorityLevel {
  Level1 = 10,
  Level2 = 20,
  Level3 = 30,
  Level4 = 40,
  Level5 = 50,
}
export enum PartialShipment {
  WholeOrderShipment = 1,
  PartialShipment,
}

export enum SalesOrderPaymentStatus {
  PartialPaid = "Partial paid",
  UnPaid = "UnPaid",
  Paid = "Paid",
}

export enum MbAnnualLimitVerification {
  Verified = 10,
  Risk = 15,
  Exceed = 20,
}

export enum QaReport {
  SystemStandard = 1,
  Requried,
}

export enum PartialWholeShipment {
  Partial = 1,
  WholeShipment,
}

export enum CustomerClearanceMode {
  DDP1210 = 10,
  DDP9610 = 20,
  DDPCC = 30,
  DDPGeneralTrade = 40,
  DAP = 50,
}

export enum PriorityHandling {
  WholeOrder = 1,
  PartialOrder,
}

export enum SettlementType {
  StandardSettlement = 1,
  PreSettlement,
}

export enum DefiniteInventorySource {
  OnHand = 10,
  SpecialPriceOfMerchant = 20,
  Agent = 30,
  InStoreRealTime = 40,
}

export enum OrderManagerPaymentMethod {
  BankTransfer = "banktransfer",
  Alipay = "easy_payment_gateway_alipay",
  BehalfPay = "easy_payment_gateway_behalfpay",
  WeChatPay = "easy_payment_gateway_wechatpay",
  PayPal = "paypal_express",
}