/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-26 18:36:39
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-27 09:49:24
 * @FilePath: /fulfillment-frontend/src/directives/inputDiscount.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Directive } from "vue";
import accounting from "accounting-js";

const checkInputDiscount = (el, binding) => {
  //   console.log(inputEl.value);
  //   inputEl.dispatchEvent(new Event("input"));
  let inputEl;
  if (el.tagName.toLowerCase() !== "el-input") {
    inputEl = el.children[0];
  } else if (el.tagName.toLowerCase() == "el-input-number") {
    inputEl = el.children[0].children[0];
  } else {
    inputEl = el;
  }
  const { value } = binding;
  inputEl.onblur = () => {
    inputEl.value.replace(/[^0-9-]+/, "").replace("+", "");
    if (Number(inputEl.value) == inputEl.value) {
      if (
        Number(inputEl.value) > 0 &&
        inputEl.value.indexOf("+") === -1 &&
        inputEl.value.indexOf("-") === -1
      ) {
        inputEl.value = "+" + accounting.toFixed(Number(inputEl.value), 2);
      }
    } else {
      inputEl.value = accounting.toFixed(0, 2);
    }
    inputEl.dispatchEvent(new Event("input"));
  };
  inputEl.oninput = () => {
    // inputEl.value = accounting.unformat(inputEl.value.toFixed(2));
  };
  inputEl.onfocus = () => {
    if (inputEl.value) {
      inputEl.value = inputEl.value.replace("+", "");
      // inputEl.dispatchEvent(new Event("input"));
    }
  };
};

export const inputDiscount: Directive = {
  mounted(el, binding) {
    checkInputDiscount(el, binding);
  },
};
