/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-05 13:38:40
 * @LastEditors: GaoXiang0403 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2023-06-22 16:33:33
 * @FilePath: /fulfillment-frontend/src/directives/user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Directive } from "vue";
import store from "@/store";

const checkUser = (el, binding) => {
  const { value } = binding;
  if (value && value.users instanceof Array) {
    const hasUser = checkUserFlag(value);
    if (!hasUser) {
      el && el.parentNode && el.parentNode.removeChild(el);
    }
  } else {
    // throw error string
    throw new Error("Need User! Like v-user={users: [12], mode: 'or'}");
  }
};

export const checkUserFlag = (binding, flag?) => {
  let hasUser = false;
  const { mode, users } = binding;
  const actionPermits = [store.getters.currentUser.id];
  if (mode == "or") {
    hasUser = users.some((item) => {
      return actionPermits.includes(item);
    });
  } else if (mode == "and") {
    hasUser = users.every((item) => {
      return actionPermits.includes(item);
    });
  } else {
    hasUser = users.some((item) => {
      return actionPermits.includes(item);
    });
  }
  console.log("Has User: " + hasUser);
  if (flag !== undefined) {
    return hasUser && flag;
  }
  return hasUser;
};

export const user: Directive = {
  mounted(el, binding) {
    checkUser(el, binding);
  },
  updated(el, binding) {
    checkUser(el, binding);
  },
};
