import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getMonitoringRulesList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/exception-handling/monitoring-rules/index",
      params
    );
  },
  addMonitoringRules(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/exception-handling/monitoring-rules/store",
      params
    );
  },
  showMonitoringRules(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/exception-handling/monitoring-rules/show",
      params
    );
  },
  updateMonitoringRules(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/exception-handling/monitoring-rules/update",
      params
    );
  },
};
