/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-07 11:30:15
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-09-01 16:07:19
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getMerchantOrderModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setMerchantOrderName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setMerchantOrderName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/merchant-order/";
  result.parentName = language("common.merchantOrder");
  if (route.path.match("order-request")) {
    strName = language("common.orderRequest");
    listPath += "order-request";
  } else if (route.path.match("order-processing")) {
    strName = language("common.orderProcessing");
    listPath += "order-processing";
  } else {
    strName = language("common.orderOverview");
    listPath += "order-overview";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getMerchantOrderDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "merchant-order-order-request-add":
    case "merchant-order-order-overview-add":
    case "merchant-order-order-overview-overview":
    case "merchant-order-order-request-overview":
    case "merchant-order-order-processing-overview":
      result.currentName = language("salesOrder.overview");
      setMerchantOrderName(language, route, result, router);
      break;
    case "merchant-order-order-request-information":
    case "merchant-order-order-processing-information":
      result.currentName = language("common.information");
      setMerchantOrderName(language, route, result, router);
      break;
    case "merchant-order-order-overview-invoices":
    case "merchant-order-order-request-invoices":
    case "merchant-order-order-processing-invoices":
      result.currentName = language("orderRequest.invoices");
      setMerchantOrderName(language, route, result, router);
      break;
    case "merchant-order-order-overview-shipments":
    case "merchant-order-order-request-shipments":
    case "merchant-order-order-processing-shipments":
      result.currentName = language("orderRequest.shipments");
      setMerchantOrderName(language, route, result, router);
      break;
    case "merchant-order-order-overview-logs":
    case "merchant-order-order-request-logs":
    case "merchant-order-order-processing-logs":
      result.currentName = language("common.logs");
      setMerchantOrderName(language, route, result, router);
      break;
  }
  return result;
};
