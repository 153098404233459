import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";
import axios from "axios";
import { Canceler } from "axios";
const CancelToken = axios.CancelToken;
import NProgress from "nprogress";

let cancelImagePreview: Canceler;

export default {
  getLogisticsDocIndex(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/logistics-document/index",
      params,
      {
        headers: {
          "X-Lang": "en_US",
          "X-Service-Trace-Id": "8135a85dd88c1",
        },
      }
    );
  },
  getLogisticsDocItemList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/logistics-document/item-list",
      params
    );
  },
  getLogisticsDocShow(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/logistics-document/show",
      params
    );
  },
  getOwnerAddress(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/address/data-source/get-owner-address",
      params
    );
  },
  addLogisticsDoc(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/logistics-document/store",
      params
    );
  },
  updateLogisticsDoc(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/logistics-document/update",
      params
    );
  },
  getAddressShow(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/address/show",
      params
    );
  },
  downloadZip(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/media/download-zip",
      params,
      { responseType: "blob" }
    );
  },
  quickTransfer(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/media/file/quick-transfer",
      params
    );
  },
  putFiles(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/media/file/put-files",
      params
    );
  },
  createItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/tms/logistics-document/create-item",
      params
    );
  },
  updateItemStatus(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/tms/logistics-document/update-item-status",
      params
    );
  },
  getLogisticsDocImagePreview(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/tms/logistics-document/get-logistics-document-image-stream",
      params,
      {
        responseType: "blob",
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelImagePreview = c;
        }),
      }
    );
  },
  cancelLogisticsDocImagePreview() {
    NProgress.done();
    cancelImagePreview("请求取消");
  },
};
